<template>
     <div class="header-sider">
      <section class="box-sider">
        <h2>XOS<span>管理平台</span></h2>
      </section>
      <div><img src="@/assets/images/home/logo.png" class="logo_home"></div>
      <section class="user-info">
          <a-popconfirm
            title="确定退出吗？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirm"
            style="width:140px;"
          >
            <div>
              <img class="userPic" src="@/assets/images/home/logo-ligong.svg" />
              <span>{{xosUserName}}</span>
            </div>
          </a-popconfirm>
      </section>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { signout } from '../../api'

export default {
  name: "HeaderSider",
  data(){
    return {
      xosUserName: ''
    }
  },
  computed:{
    ...mapState(['homeTitle','subTitle','sanTitle','homeText']),
  },
  created(){
    this.xosUserName = localStorage.getItem('xosUserName')
  },
  methods: {
    async confirm() {
      await signout().then(({errNo})=>{
        if(errNo === 0){
          localStorage.removeItem('xosAdminToken')
          this.$router.push('/login')
        }
      })
      
    },
  }
}
</script>

<style lang="scss" scoped>
.header-sider{
  color: #B9B9B9;
  height: 64px;
  font-size: 14px;
  background: #232323;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #161616;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.03);
  .user-info{
    padding: 0px 20px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    .userPic
    {
      width: 36px;
      height: 36px;
      margin: 0 10px;
    }
  }
  .header-menu{
    height: 80px;
    color: #fff !important;
    padding: 10px 20px 0 20px ;
    h3{
      font-size: 24px;
      line-height: 36px;
      margin-right: 30px;
      color: #E8E8E8;
    }
    .menu-info{
      display: flex;
      p{
        margin:-12px 0 0 0;
      }
    }
  }
  .box-sider{
    padding: 0 10px;
    color: #fff;
    display: flex;
    flex-wrap: nowrap;
    justify-content:center;
    align-items:center;
    height: 64px;
    line-height: 60px;
    margin: 0 20px;
    h2{
      font-size: 32px;
      height: 40px;
      color: #fff;
      margin-right: 6px;
    }
    span{
      font-size: 18px;
      vertical-align: middle; 
      white-space: nowrap !important;

    }
  }
  .logo_home{
    width: 51px;
    height: 38px;
  }
}
.text1 {
  color: #fff;
}
</style>
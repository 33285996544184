const state = {
    homeTitle: '',
    subTitle: '',
    sanTitle: '',
    homeText: '',
    ws: null,
    wsStatusConnection: false, // WS连接状态
    WSActiveClose: false, // WS主动关闭
    wsData: '',
    xosAdminToken: '',
};

export default state;
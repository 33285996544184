import axios from "axios";
import { message } from "ant-design-vue";
import router from "../router";

// const requestTime = 6000;
const instanceAxios = axios.create({
    timeout: 500000,
});
message.config({
    top: `50px`,
    duration: 8,
    maxCount: 2,
});
/*
 * 请求拦截
 */
instanceAxios.interceptors.request.use(
    (config) => {
        const xosAdminToken = localStorage.getItem("xosAdminToken");
        if (config.url !== "/v1/user/signin") {
            config.headers.accessToken = xosAdminToken;
        }
        return config;
    },
    (error) => {
        message.error(error.message, 10);
        return Promise.reject(error);
    }
);

/*
 * 响应拦截
 */
instanceAxios.interceptors.response.use(
    (res) => {
        return res.data;
    },
    (error) => {
        responseError(error);
        return Promise.reject(error);
    }
);

function responseError(error) {
    if (error && error.response) {
        switch (error.response.status) {
            case 201:
                message.error(`请求错误 201, 错误信息 ${error.message}`, 10);
                break;
            case 401:
                message.error(`请求错误 401, 错误信息 ${error.message}`, 10);
                localStorage.removeItem("xosAdminToken");
                router.replace({ path: "/login", replace: true });
                break;
            case 403:
                message.error(`请求错误 403, 错误信息 ${error.message}`, 10);
                break;
            case 404:
                message.error(`请求错误 404, 错误信息 ${error.message}`, 10);
                break;
            case 502:
                message.error(`请求错误 502, 错误信息 ${error.message}`, 10);
                break;
            default:
                message.error(error.message, 10);
                break;
        }
    } else {
        message.error(error.message, 10);
    }
}

//get方法
export function get(url, params) {
    return instanceAxios.get(url, { params });
}

//get方法 url方式
export function getParams(url) {
    return instanceAxios.get(`${url}`);
}

//post方法
export function post(url, params) {
    return instanceAxios.post(url, params);
}

//post方法 url方式
export function postParams(url) {
    return instanceAxios.post(url);
}

//del方法
export function del(url, params) {
    const config = {
        method: "delete",
        url: url,
    };
    if (params) config.params = params;
    return instanceAxios.delete(url, config);
}

// url方式单个参数 delete
export function delSingleParam(url, params) {
    return instanceAxios.delete(`${url}${params}`);
}

// params方式 delete
export function delParams(url, params) {
    const config = {
        method: "delete",
        url: url,
    };
    if (params) config.data = params;
    return instanceAxios.delete(url, config);
}

//put方法
export function put(url, data) {
    console.log(url, data)
    return instanceAxios.put(url, data);
}

export default instanceAxios;
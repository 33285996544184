import {get, post } from '../http'
import { baseUrls } from '../config/urls'
// api
const aioUrl = baseUrls.aio
    // 钉钉扫码登录初始化参数
export function scan(params) {
    return get(`${aioUrl}/v1/user/scan/${params}`, )
}
// ⽤⼾密码/验证码登录
export function signin(params) {
    return post(`${aioUrl}/v1/user/signin`, params)
}
// 账号注册
export function signup(params) {
    return post(`${aioUrl}/v1/user/signup`, params)
}

//忘记密码
export function resetPasswd(params) {
    return post(`${aioUrl}/v1/user/reset-passwd`, params)
}

//获取⽤⼾权限菜单
export function menu() {
    return get(`${aioUrl}/v1/user/menu`)
}

//发送短信验证码
export function smsSend(params) {
    return post(`${aioUrl}/v1/sms/send`, params)
}

// 钉钉扫码跳转
export function redirect(params) {
    return post(`${aioUrl}/v1/user/redirect`, params)
}

// 用户退出
export function signout() {
    return get(`${aioUrl}/v1/user/signout`)
}
<template>
      <div class="header-menu">
        <a-breadcrumb>
          <a-breadcrumb-item class="text1">主页</a-breadcrumb-item>
          <a-breadcrumb-item class="text1">{{homeTitle}}</a-breadcrumb-item>
          <a-breadcrumb-item v-if="subTitle">{{subTitle}}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="menu-info">
          <h3>{{sanTitle}}</h3>
          <p>{{homeText}}</p>
        </div>
      </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "Breadcrumbs",
  data(){
    return {
    }
  },
  computed:{
    ...mapState(['homeTitle','subTitle','sanTitle','homeText']),
  },
}
</script>

<style lang="scss" scoped>
.header-menu{
    background: #232323;
    text-align: left;
    height: 80px;
    font-size: 14px;
    color: #fff !important;
    padding: 10px 20px 0 20px ;
    .menu-info{
      display: flex;
      h3{
        font-size: 20px;
        line-height: 36px;
        margin-right: 30px;
        color: #E8E8E8;
      }
      p{
        margin:6px 0 0 0;
        font-size: 14px;
      }
    }
  }
.text1 {
  color: #fff;
}
</style>
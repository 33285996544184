<template>
  <div class="containtest">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" ></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "ContentSider",
  data(){
    return {
    
    }
  }
}
</script>

<style lang="scss" scoped>
.containtest{
  overflow: auto;
  height: 86vh;
}
</style>
<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
    data() {
      return {
        locale: zhCN,
      };
    },
  };
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 14px;
  color: #B9B9B9;
  background: #232323;
}

nav {
  padding: 30px;

  a {
    font-size: 14px;
    font-weight: bold;
    color: #B9B9B9;

    &.router-link-exact-active {
      color: #B9B9B9;
    }
  }
}
</style>

<template>
  <div class="app-wrap">
   <HeaderSider></HeaderSider>

    <a-layout style="background: #262626;">
      <LeftSider></LeftSider>
      <a-layout>
        <!-- 头部header -->
        <!-- <a-layout-header class="app-header"> -->
          <!-- <a-button type="primary" style="margin-bottom: 16px" @click="toggleCollapsed">
            <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
          </a-button> -->
        <!-- </a-layout-header> -->
        <!-- 主体content -->
        <a-layout-content class="app-content">
          <Breadcrumbs></Breadcrumbs>
        <!-- 主体content -->
          <ContentSider></ContentSider>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import HeaderSider from './components/HeaderSider.vue'
import ContentSider from './components/ContentSider.vue'
import LeftSider from './components/LeftSider.vue'
import Breadcrumbs from './components/Breadcrumbs.vue'


export default {
  name: "layout",
  data(){
    return {
      collapsed: false,
    }
  },

  components:{
    HeaderSider,
    ContentSider,
    LeftSider,
    Breadcrumbs,
  },
  methods:{
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
  }
}
</script>

<style lang="scss" scoped>
.app-wrap {
  width: 100vw;
  height: 100vh;
  background: #262626 !important;
  overflow: hidden;
}

.app-header {
  padding: 0;
  text-align: left;
  background: #232323;
  height: 130px;
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
}
.app-content{
  
background: #161616;
}
</style>
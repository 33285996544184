import Vue from "vue";
import VueRouter from "vue-router";
import Layout from '../layout'
import store from '@/store'

Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "home",
        component: Layout,
        redirect: '/home',
        children: [{
                path: 'home',
                name: 'Home',
                component: () =>
                    import ('@/views/equipment/lists'),
                meta: {
                    title: '首页',
                    text: '',
                    keepAlive: true,
                }
            },
            {
                path: "/equipment",
                name: "equipment",
                component: () =>
                    import ( /* webpackChunkName: "equipment" */ "@/views/equipment"),
                meta: {
                    title: '设备列表',
                    text: '',
                    keepAlive: true
                }
            },
            {
                path: "/lists",
                name: "lists",
                component: () =>
                    import ( /* webpackChunkName: "equipment" */ "@/views/equipment/lists"),
                meta: {
                    title: '设备管理',
                    subTitle: '',
                    sanTitle: '设备列表',
                    text: '编辑设备信息，管理设备状态，新增设备信息等。',
                    keepAlive: true
                }
            },
            {
                path: "/editEquip",
                name: "editEquip",
                component: () =>
                    import ( /* webpackChunkName: "equipment" */ "@/views/equipment/lists/edit"),
                meta: {
                    title: '设备管理',
                    subTitle: '',
                    sanTitle: '设备编辑',
                    text: '编辑设备信息，管理设备状态，新增设备信息等。',
                    keepAlive: true
                }
            },
            {
                path: "/urmanagement",
                name: "urmanagement",
                component: () =>
                    import ( /* webpackChunkName: "equipment" */ "@/views/equipment/urmanagement"),
                meta: {
                    title: '设备管理',
                    subTitle: '',
                    sanTitle: '机械臂程序管理',
                    text: '',
                    keepAlive: true
                }
            },
            {
                path: "/machineTool",
                name: "machineTool",
                component: () =>
                    import ( /* webpackChunkName: "machineTool" */ "@/views/machine_tool"),
                meta: {
                    title: '机床管理',
                    text: '',
                    keepAlive: true
                }
            },
            {
                path: "/machine",
                name: "machine",
                component: () =>
                    import ( /* webpackChunkName: "machineTool" */ "@/views/machine_tool/machine"),
                meta: {
                    title: '机床管理',
                    subTitle: '',
                    sanTitle: '机床管理',
                    text: '编辑机床信息，管理机床状态，新增机床设备信息等。',
                    keepAlive: true
                }
            },
            {
                path: "/addMachine",
                name: "addMachine",
                component: () =>
                    import ( /* webpackChunkName: "machineTool" */ "@/views/machine_tool/machine/add"),
                meta: {
                    title: '机床管理',
                    subTitle: '',
                    sanTitle: '新建机床',
                    text: '新增机床设备信息。',
                    keepAlive: true
                }
            },
            {
                path: "/editMachine",
                name: "editMachine",
                component: () =>
                    import ( /* webpackChunkName: "machineTool" */ "@/views/machine_tool/machine/edit"),
                meta: {
                    title: '机床管理',
                    subTitle: '',
                    sanTitle: '新建机床',
                    text: '编辑机床信息，管理机床状态。',
                    keepAlive: true
                }
            },
            {
                path: "/toolmanagement",
                name: "toolmanagement",
                component: () =>
                    import ( /* webpackChunkName: "machineTool" */ "@/views/machine_tool/toolmanagement"),
                meta: {
                    title: '机床管理',
                    subTitle: '',
                    sanTitle: '机床管理',
                    text: '编辑机床信息，管理机床状态，新增机床设备信息等。',
                    keepAlive: true
                }
            },
            {
                path: "/production",
                name: "production",
                component: () =>
                    import ( /* webpackChunkName: "production" */ "@/views/production"),
                meta: {
                    title: '生产管理 ',
                    text: '',
                    keepAlive: true
                }
            },
            // {
            //     path: "/aps",
            //     name: "aps",
            //     component: () =>
            //         import ( /* webpackChunkName: "production" */ "@/views/production/aps"),
            //     meta: {
            //         title: '生产管理 ',
            //         subTitle: '排产管理 ',
            //         sanTitle: '排产管理 ',
            //         text: '配置排产信息。',
            //         keepAlive: true
            //     }
            // },
            // {
            //     path: "/addAps",
            //     name: "addAps",
            //     component: () =>
            //         import ( /* webpackChunkName: "production" */ "@/views/production/aps/add"),
            //     meta: {
            //         title: '生产管理 ',
            //         subTitle: '排产管理 ',
            //         sanTitle: '排产-表单设计',
            //         text: '自定义设计排产表单。',
            //         keepAlive: true
            //     }
            // },
            // {
            //     path: "/editAps",
            //     name: "editAps",
            //     component: () =>
            //         import ( /* webpackChunkName: "production" */ "@/views/production/aps/edit"),
            //     meta: {
            //         title: '生产管理 ',
            //         subTitle: '排产管理 ',
            //         sanTitle: '排产-设置编辑',
            //         text: '编辑排产设置信息。',
            //         keepAlive: true
            //     }
            // },
            {
                path: "/yidaset",
                name: "yidaset",
                component: () =>
                    import ( /* webpackChunkName: "production" */ "@/views/production/yidaset"),
                meta: {
                    title: '生产管理',
                    subTitle: '宜搭配置 ',
                    sanTitle: '宜搭配置',
                    text: '配置宜搭参数',
                    keepAlive: false
                }
            },
            {
                path: "/addYidaset",
                name: "addYidaset",
                component: () =>
                    import ( /* webpackChunkName: "production" */ "@/views/production/yidaset/add"),
                meta: {
                    title: '生产管理',
                    subTitle: '宜搭配置 ',
                    sanTitle: '表单设计',
                    text: '编辑表单信息',
                    keepAlive: false
                }
            },
            {
                path: "/editYidaset",
                name: "editYidaset",
                component: () =>
                    import ( /* webpackChunkName: "production" */ "@/views/production/yidaset/edit"),
                meta: {
                    title: '生产管理',
                    subTitle: '宜搭配置 ',
                    sanTitle: '宜搭配置',
                    text: '配置宜搭参数',
                    keepAlive: false
                }
            },
            // {
            //     path: "/workmanagement",
            //     name: "workmanagement",
            //     component: () =>
            //         import ( /* webpackChunkName: "production" */ "@/views/production/workmanagement"),
            //     meta: {
            //         title: '生产管理',
            //         subTitle: '报工管理 ',
            //         sanTitle: '报工管理',
            //         text: '编辑报工信息等。',
            //         keepAlive: true
            //     }
            // },
            // {
            //     path: "/addWorkmanagement",
            //     name: "addWorkmanagement",
            //     component: () =>
            //         import ( /* webpackChunkName: "production" */ "@/views/production/workmanagement/add"),
            //     meta: {
            //         title: '生产管理',
            //         subTitle: '报工管理 ',
            //         sanTitle: '报工-表单设计',
            //         text: '配置排产、报工、班次信息等。',
            //         keepAlive: true
            //     }
            // },
            // {
            //     path: "/editWorkmanagement",
            //     name: "editWorkmanagement",
            //     component: () =>
            //         import ( /* webpackChunkName: "production" */ "@/views/production/workmanagement/edit"),
            //     meta: {
            //         title: '生产管理',
            //         subTitle: '报工管理 ',
            //         sanTitle: '报工-设置编辑',
            //         text: '配置排产、报工、班次信息等。',
            //         keepAlive: true
            //     }
            // },
            {
                path: "/shiftmanagement",
                name: "shiftmanagement",
                component: () =>
                    import ( /* webpackChunkName: "production" */ "@/views/production/shiftmanagement"),
                meta: {
                    title: '生产管理',
                    subTitle: '班次管理 ',
                    sanTitle: '班次管理',
                    text: '设置生产班次信息。',
                    keepAlive: true
                }
            },
            {
                path: "/datamanagement",
                name: "datamanagement",
                component: () =>
                    import ( /* webpackChunkName: "production" */ "@/views/production/datamanagement"),
                meta: {
                    title: '生产管理',
                    subTitle: '数据管理 ',
                    sanTitle: '数据管理',
                    text: '编辑数据和导出数据列表',
                    keepAlive: true
                }
            },
            {
                path: "/history",
                name: "history",
                component: () =>
                    import ( /* webpackChunkName: "production" */ "@/views/production/history"),
                meta: {
                    title: '生产管理',
                    subTitle: '历史记录 ',
                    sanTitle: '历史记录',
                    text: '查看历史记录',
                    keepAlive: true
                }
            },
            {
                path: "/drawings",
                name: "drawings",
                component: () =>
                    import ( /* webpackChunkName: "drawings" */ "@/views/drawings"),
                meta: {
                    title: '图纸管理',
                    subTitle: '',
                    sanTitle: '图纸管理',
                    text: '编辑图纸信息，管理图纸，新增图纸信息等。',
                    keepAlive: true
                }
            },
            {
                path: "/importRecords",
                name: "importRecords",
                component: () =>
                    import( /* webpackChunkName: "drawings" */ "@/views/drawings/importRecords"),
                meta: {
                    title: '图纸管理',
                    subTitle: '导入记录',
                    sanTitle: '导入记录',
                    text: '编辑图纸信息，管理图纸，新增图纸信息等。',
                    keepAlive: false
                }
            },
            {
                path: "/api",
                name: "api",
                component: () =>
                    import ( /* webpackChunkName: "drawings" */ "@/views/test/api"),
            },

        ]
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import ( /* webpackChunkName: "login" */ "@/views/login"),
        meta: {
            title: '用户登录',
            keepAlive: true,
            alreadyLogin: true
        }
    },
    {
        path: "/register",
        name: "register",
        component: () =>
            import ( /* webpackChunkName: "register" */ "@/views/register"),
        meta: {
            title: '用户注册',
            keepAlive: true
        }
    },
    {
        path: "/fgpwd",
        name: "fgpwd",
        component: () =>
            import ( /* webpackChunkName: "register" */ "@/views/password"),
        meta: {
            title: '忘记密码',
            keepAlive: true
        }
    },

];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const xosAdminToken = localStorage.getItem('xosAdminToken')
    if (to.path === '/login') {
        if (xosAdminToken && to.meta.alreadyLogin) {
            next('/')
        } else {
            next()
        }
    } else {
        if (!xosAdminToken && to.path !== '/register' && to.path !== '/fgpwd') {
            next('/login')
        } else {
            next()
        }
    }
    store.state.homeTitle = to.meta.title
    store.state.subTitle = to.meta.subTitle
    store.state.sanTitle = to.meta.sanTitle
    store.state.homeText = to.meta.text
})

export default router;
const mutations = {
    //公用方法，state赋值
    stateValueHandler(state, { name, value }) {
        state[name] = value;
    },
    wsData(state, value) {
        state.wsData = value
    }
};

export default mutations;
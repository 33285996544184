<template>
  <div>
    <a-layout-sider
      :collapsed="collapsed"
      :trigger="null"
      collapsible
      class="left-sider"
      style="background: #262626;"
    >
      <a-menu 
      :defaultSelectedKeys="[$route.path]"
      :defaultOpenKeys="['sub1','sub2','sub3']" 
      theme="dark" 
      mode="inline" style="background: #262626;">
          <a-sub-menu key="sub1">
            <span slot="title"><img class="icon_wh" src="@/assets/images/menu/icon_equipment.svg" /><span>设备管理</span></span>
            <a-menu-item :key="'/lists'">
              <router-link to="lists">
                设备列表
              </router-link>
            </a-menu-item>
            <!-- <a-menu-item :key="'/urmanagement'">
              <router-link to="urmanagement">
                机械臂程序管理
              </router-link>
            </a-menu-item> -->
          </a-sub-menu>
          <a-sub-menu key="sub2">
            <span slot="title"><img class="icon_wh" src="@/assets/images/menu/icon_machineTool.svg" /><span>机床管理</span></span>
            <a-menu-item key="/machine">
              <router-link to="machine">
                机床管理 
              </router-link>
            </a-menu-item>
            <a-menu-item key="/toolmanagement">
              <router-link to="toolmanagement">
                刀具管理
              </router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="sub3">
            <span slot="title"><img class="icon_wh" src="@/assets/images/menu/icon_production.svg" /><span>生产管理</span></span>
            <!-- <a-menu-item key="/aps">
              <router-link to="aps">
                排产管理 
              </router-link>
            </a-menu-item>
            <a-menu-item key="/workmanagement">
              <router-link to="workmanagement">
                报工管理
              </router-link>
            </a-menu-item> -->
            <a-menu-item key="/yidaset">
              <router-link to="yidaset">
                宜搭配置
              </router-link>
            </a-menu-item>
            <a-menu-item key="/shiftmanagement">
              <router-link to="shiftmanagement">
                班次管理
              </router-link>
            </a-menu-item>
            <a-menu-item key="/datamanagement">
              <router-link to="datamanagement">
                数据管理
              </router-link>
            </a-menu-item>
            <a-menu-item key="/history">
              <router-link to="history">
                历史记录
              </router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item :key="'/drawings'">
            <router-link to="drawings">
              <img class="icon_wh" src="@/assets/images/menu/icon_drawings.svg" />
              图纸管理
            </router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
  </div>
</template>

<script>
export default {
  name: "LeftSider",
  data(){
    return {
      collapsed: false,
      defaultSelectedKeys: this.$route.path,
      selectedKeys: ['sub1','sub2','sub3']
    }
  },


}
</script>

<style lang="scss" scoped>
.left-sider{
  height: 100vh;
  // width: 100vw;
  width: 277px;
  min-width: 277px;
  z-index:999;
  background: #262626;
  box-sizing: content-box;
}
a {
  color: #fff;
}
.router-link-active {
  color: #fff;
}
.ant-menu-submenu-title{
  position:relative;
}
.ant-menu-submenu-title span{
  font-size: 18px !important;
}
.icon_wh{
  width: 30px;
  height: 30px;
  vertical-align: center;
  position: relative;
  left:-8px;
  top:0;
}

.ant-menu li{
  margin: 0!important;
  background: #262626;
  padding: 0;
  font-size: 16px !important;
}
.ant-menu-item {
  height: 58px !important;
  line-height: 58px !important;
  // font-size: 16px !important;
  text-align: left;
  width: 277px;
  background: #262626 !important;
  position: relative;
}
.ant-menu-submenu{
  text-align: left !important;
}
.ant-layout * {
    box-sizing: border-box;
}
.ant-menu-item-selected{
  background: linear-gradient(270deg, rgba(0,255,112,0.55) 0%, rgba(0,255,112,0.14) 100%) !important;
  background:transparent;
}
.ant-menu-item-active{
  background: linear-gradient(270deg, rgba(0,255,112,0.55) 0%, rgba(0,255,112,0.14) 100%) !important;
}
.ant-menu-dark,.ant-menu-inline.ant-menu-sub{
  background: #262626 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub{
  background: #262626 !important;
}
// .ant-menu .ant-menu-inline  .ant-menu-sub
.ant-menu{
  background: #262626 !important;
}
.ant-menu-sub{
  background: #262626 !important;
}
.ant-menu-sub{
  background: #262626 !important;
}
</style>